<template>
  <div :id="uniqueId" class="storySubHeader" v-observe-visibility="visibilityChanged" style="margin: 0 auto;" ></div>
</template>

<script>
export default {
  name: 'SubHeading',
  props: ['title', 'uniqueId'],
  data() {
        return {
            i: 0,
            speed: 30,
        }
  },
  methods: {
    visibilityChanged (isVisible) {
        if (isVisible) {
            this.typeWriter();
        }else {
            this.resetTypeWriter();
        }
    },
    typeWriter() {
      if (this.i < this.title.length) {
        document.getElementById(this.uniqueId).innerHTML += this.title.charAt(this.i);
        this.i++;
        setTimeout(() => this.typeWriter(), this.speed);
      }
    },
    resetTypeWriter(){
        document.getElementById(this.uniqueId).innerHTML = "";
        this.i= 0;

    }
  },
  mounted() {} 
}
</script>

<style scoped>
.storySubHeader{
    color: gray;
    font-size: 18px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
}
</style>
