<template>
  <h1 :id="uniqueId" class="storyHeader" v-observe-visibility="visibilityChanged" ></h1>
</template>

<script>
export default {
  name: 'Heading',
  props: ['title', 'uniqueId', 'speed'],
  data() {
        return {
            i: 0,
        }
  },
  methods: {
    visibilityChanged (isVisible) {
        if (isVisible) {
            this.typeWriter();
        }else {
            this.resetTypeWriter();
        }
    },
    typeWriter() {
      if (this.i < this.title.length) {
        document.getElementById(this.uniqueId).innerHTML += this.title.charAt(this.i);
        this.i++;
        setTimeout(() => this.typeWriter(), this.speed);
      }
    },
    resetTypeWriter(){
        document.getElementById(this.uniqueId).innerHTML = "";
        this.i= 0;

    }
  },
  mounted() {} 
}
</script>

<style scoped>

</style>
