<template>
  <div class="slideshow-container">

    <div class="mySlides fade">
      <img src="../assets/NFTs/NF-Tron-01-01_Headline.png" style="width:100%">
    </div>

    <div class="mySlides fade">
      <img src="../assets/NFTs/slider1.png" style="width:100%">
    </div>

    <div class="mySlides fade">
      <img src="../assets/NFTs/slider2.png" style="width:100%">
    </div>

    <div class="mySlides fade">
      <img src="../assets/NFTs/slider3.png" style="width:100%">
    </div>

    <div class="mySlides fade">
      <img src="../assets/NFTs/slider4.png" style="width:100%">
    </div>

    <div class="mySlides fade">
      <img src="../assets/NFTs/slider5.png" style="width:100%">
    </div>

    <div class="mySlides fade">
      <img src="../assets/NFTs/slider6.png" style="width:100%">
    </div>

    <div class="mySlides fade">
      <img src="../assets/NFTs/slider7.png" style="width:100%">
    </div>

    <div style="text-align:center; margin-top: 20px;">
      <span class="dot"></span> 
      <span class="dot"></span> 
      <span class="dot"></span> 
      <span class="dot"></span>
      <span class="dot"></span>
      <span class="dot"></span>
      <span class="dot"></span>
      <span class="dot"></span> 
    </div>

  </div>
</template>

<script>
export default {
  name: 'ImageGalleryMobile',
  props: [],
  components: {
   },
   data() {
     return {
       slideIndex: 1
     }
   },
  methods: {
    showSlides() {
      var slides = document.getElementsByClassName("mySlides");
      var dots = document.getElementsByClassName("dot");
      var i;
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";  
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
      }
      this.slideIndex++;
      if (this.slideIndex > slides.length) {this.slideIndex = 1}
      dots[this.slideIndex-1].className += " active";
      slides[this.slideIndex-1].style.display = "block";  
      setTimeout(this.showSlides, 2000); // Change image every 2 seconds
    }
  },
  computed: {
  },
  mounted() {
    this.showSlides(this.slideIndex);
  }
}
</script>

<style scoped>
.mySlides {display: none;}
img {
  vertical-align: middle;
  border-radius: 10px;
}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}


/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .text {font-size: 11px}
}
.mySlides{
  margin-right: auto;
  margin-left: auto;
}
/* The dots/bullets/indicators */
.dot {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active {
  background-color: #717171;
}
</style>
