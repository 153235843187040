<template>
<div class="CategorySection story">
  <div class="imageAndTextMargin">
    <div class="imageAndTextWrapper"> 
      <div class="sticky">
        <SubHeading class="subHeading" style="margin-bottom: 16px" title="FUTUREBOTS" uniqueId="storySubHeading"/>
        <Heading class="heading" style="margin-bottom: 20px" title="THE FIRST NEWSPAPER ROBOTS ON THE BLOCKCHAIN" uniqueId="storyHeading" speed="15"/>
        <div v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'text')" class="storyText" :class="{ 'animate-text-block' : textVisible }">
          <p>Our FUTUREBOTs show thought-provoking future predictions and aim to generate attention. The FUTUREBOTs are from the year 2050. Each FUTUREBOT shows the headline of the NFT World News Dashboard for one day. As a result, the collection is limited to 365 FUTUREBOTs. Each FUTUREBOT is unique in composition and has its own headline.</p>
          <p><b>SHOW THE WORLD THAT YOU CARE ABOUT THE FUTURE - THAT'S WHAT OUR FUTUREBOT STANDS FOR!</b></p>
          <!--p>The FUTUREBOTs are just the beginning. Our second project is nearing completion. The first data-driven NFT World News Dashboard. If you show the world that you care about the future by owning a FUTUREBOT from our upcoming collection, you can win our daily NFT World News Dashboard.</p> !-->
          <p><b>FUTUREBOTS ARE ONLY AVAILABLE ON OPENSEA.</b></p>
        </div>
      </div>
      <div class="imageWrapper">
        <div class="imageSubWrapper">
          <img v-if="!phone" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'image')" src="../assets/NFTs/NF-Tron-01-01_Headline.png" alt="Plastic Pollution" class="storyImage" :class="{ 'opacityFadeEffect' : imageVisible }">
          <ImageGalleryMobile v-if="phone" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'image')" :class="{ 'opacityFadeEffect' : imageVisible }"/>
        </div>
      </div>
    </div>
  </div>
  <div class="aspect-ratio-box aspect-square"><lord-icon trigger="loop" class="aspect-ratio-box-inside editor-preview"></lord-icon></div>
</div>
</template>

<script>
// @ is an alias to /src
import SubHeading from '@/components/TextElements/SubHeading.vue'
import Heading from '@/components/TextElements/Heading.vue'
import ImageGalleryMobile from '@/components/ImageGalleryMobile.vue'
export default {
  name: 'Story',
  components: {
    SubHeading,
    Heading,
    ImageGalleryMobile
  },
  props: [],
  data() {
        return {
            textVisible: false,
            imageVisible: false,
            phone: true
        }
  },
  methods: {
    visibilityChanged (isVisible, entry, element) {
      if (element == 'text') {
        this.textVisible = isVisible;
      }
      if (element == 'image') {
         this.imageVisible = isVisible;
      }
    },
    setMobileOrNormal(){
      if (window.innerWidth <= 550) {
        this.phone = true
      }else{
        this.phone = false
      }
    }
  },
  mounted() {
    this.setMobileOrNormal()
    window.addEventListener("resize", this.setMobileOrNormal);
  } 
}
</script>

<style scoped>
.storyButtonWrapper{
  margin-top: 20px;
  margin-bottom: 30px;
}

.storyButton{
    background: rgb(74, 74, 76);
    border: 1px solid rgb(74, 74, 76);
    padding: 10px 30px;
    border-radius: 20px;
    color: white;
    text-decoration: none;
}

.heading{
  max-width: 600px;
  text-align: left;
  height: 96px;
}

@media screen and (max-width: 543px) {
  .heading{
    height: 144px;
  }
}

@media screen and (max-width: 414px) {
  .heading{
    height: 190px;
  }
}


.subHeading{
    text-align: left;
}
.story{
  text-align: center
}
.sticky{
  margin-left: 5%;
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 10px;
  align-self: flex-start; /* <-- this is the fix */
  padding-top: 25px;
}
@media screen and (max-width: 1096px) {
  .sticky{
      padding-top: 0px;
  }
}
.horizontal{
  display: block;
  border-top: 4px solid #ccc;
  border-radius: 50px;
  margin: 1.5em 0;
  padding: 0;
  width: 50px;
  margin-left: auto;
  margin-right: auto;
}
p{
  max-width: 600px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.imageAndTextMargin{
    z-index: 1;
    width: 100%;
    max-width: 1500px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 30px;
    padding-left: 30px;
}
.imageAndTextWrapper{
    display: flex;
    /**margin: 0px +150px -15px;**/
    flex-flow: row-reverse;
}

.storyText{
  text-align: left;
}

@media screen and (max-width: 1311px) {
  .imageAndTextWrapper{
      margin: 0px;
  }
  .imageWrapper{
    margin: auto;
  }
  .storyText{
    margin-bottom: 25px;
    
  }
}

@media screen and (max-width: 1096px) {
  .imageAndTextWrapper{
      display: flex;
      /**margin: 0px +150px -15px;**/
      flex-flow: row-reverse wrap;
  }
  .sticky{
    width: 100%;
    position: relative;
    margin-left: 0px;
  }
  .heading{
    max-width: 100%;
  }
  p{
    max-width: 100%;
  }
  .imageWrapper{
    margin-top: 20px;
  }
}

.imageWrapper{
    flex: 1 1 50%;
    margin-right: auto;
    width: 50%;
}

.storyImage{
    padding-right: 0px;
    border: 0px;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
    object-fit: contain;
    border-radius: 10px;
    width: 100%;
    object-fit: contain;
    max-height: inherit;
}

</style>
